<template>
  <div class="missions-preview content">
    <MissionsGraph
      v-if="graphData"
      :graphData="graphData"
      :missionsData="missionsData"
      :isMissionPreview="true"
    />
    <TableLoader v-else/>
  </div>

</template>

<script>
import MissionsGraph from '@/shared/components/supportModels/achievements/MissionsGraph.vue';
import { mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import axios from 'axios';
import TableLoader from '@/components/table/Loader.vue';

export default {
  name: 'PreviewMission',
  components: { TableLoader, MissionsGraph },
  data() {
    return {
      ready: false,
      graphData: null,
      missionsData: null
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('achievements', [
      'handleGetAchievements_item',
      'handleDeleteAchievements',
      'handleGetAchievementsByQuery'
    ]),
    ...mapActions('rewards', ['handleGetRewardsByQuery']),
    async initialize() {
      await this.handleGetAchievements_item([this.$route.params.id], 1, 0).then((data) => {
        routerBreadcrumbs(this.$router.currentRoute, {
          name: data[0].name,
        });
        this.ready = true;
      });

      const token = localStorage.getItem('vue-token');

      const hostname = window.location.hostname;
      const isStagingDev = hostname.includes('127.0.0.1');
      const isStaging = hostname.split('.').includes('staging');

      const baseUrl = isStagingDev || isStaging
        ? `https://api.staging.ziqni.io`
        : `https://api.ziqni.com`;

      const path = `${baseUrl}/graphs`;

      const entityGraphRequest = {
        ids: [this.$route.params.id],
        constraints: [],
        languageKey: '',
        includes: [],
        entityType: 'Achievement'
      };

      const { data } = await axios.post(path, entityGraphRequest, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ token }`
        }
      });

      const nodesIds = data.data.nodes.map(node => node.entityId);

      const subarray = [];
      for (let i = 0; i < Math.ceil(nodesIds.length / 20); i++) {
        subarray[i] = nodesIds.slice((i * 20), (i * 20) + 20);
      }

      const achievementsSubarray = [];

      for (let i = 0; i < subarray.length; i++) {
        const achievements = await this.handleGetAchievementsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'id',
                queryValues: subarray[i]
              }
            ],
            skip: 0,
            limit: 20
          }
        });

        for (const achievement of achievements) {
          const rewardData = await this.handleGetRewardsByQuery({
            queryRequest: {
              should: [
                {
                  queryField: 'entityId',
                  queryValues: [achievement.id]
                }
              ],
              shouldMatch: 1
            }
          });

          achievement.rewards = rewardData;
        }

        achievementsSubarray.push(achievements);
      }

      this.missionsData = achievementsSubarray.flat();

      this.graphData = data.data;

    }
  }
};
</script>

<style scoped lang="scss">
.missions-preview {
  //width: 100%;
  height: calc(100vh - 210px);
}
</style>
